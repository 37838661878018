<template>
  <v-layout column wrap>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">FOR RELEASED YEARLY DIVIDEND</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-flex xs12 class="mx-2 mt-4">
        <v-layout row wrap class="align-center">
          <v-flex xs12 md4>
            <v-select
              v-model="year_of"
              class="mx-3 pt-5"
              :items="year_of_items"
              item-value="year"
              item-text="year"
              label="Year Of"
              required
              dense
              @change="get_list_of_new_member_items"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
        </v-layout>
        <v-layout row wrap class="align-center">
          <v-flex xs12 md4>
            <v-select
              v-model="type_of_member"
              class="mx-3 pt-5"
              :items="['Regular Member','Associate Member','Member Only','Non Member']"
              label="Type of Member"
              required
              @change="get_list_of_new_member_items"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 class="mx-2">
        <v-layout row wrap class="align-center">
          <v-flex xs12 md4 class="mx-3">
                        <span class="headline" v-if="can_print">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-data-table
        class="pt-4"
        :height="height"
        :headers="headers"
        :items="list_of_new_member"
        :search="search"
        :loading="data_table_loading"
        loading-text="Loading... Please wait"
        disable-pagination
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item.no="{ item }">
          {{list_of_new_member.map(function(x) {return x.id; }).indexOf(item.id)+1}}
        </template>
        <template v-slot:item.full_name="{ item }">
          {{item.members.last_name+', '+item.members.first_name+' '+item.members.middle_name}}
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon
            v-if="item.is_released===0"
            class="mr-2"
            color="warning"
            @click="released_dividend_data(item,'Savings')"
          >
            {{icons.mdiAccountCashOutline }}
          </v-icon>
        </template>
        <template v-slot:item.action2="{ item }">
          <v-icon
            v-if="item.is_released===0"
            class="mr-2"
            color="success"
            @click="released_dividend_data(item,'Share Capital')"
          >
            {{icons.mdiCash100 }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
  import {
    mapActions, mapGetters
  } from 'vuex'
  import moment from "moment";
  import {
    mdiAccountSearchOutline, mdiPrinter, mdiCashCheck,
    mdiAccountCashOutline, mdiCash100
  } from '@mdi/js'

  export default {
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
          mdiCashCheck,
          mdiAccountCashOutline,
          mdiCash100
        },
      }
    },
    mounted() {
      this.retrieve_all_transaction_year()
        .then((response) => {
          this.year_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    data() {
      return {
        selected_month_of: moment().format('MMMM DD, YYYY'),
        year_of_items: [],
        year_of: '',

        can_print: false,
        can_released: false,
        type_of_member: 'Regular Member',
        list_of_new_member: [],
        paid_members: 0,
        total_shares: 0,
        total_asm: 0,
        total_til: 0,
        total_ic: 0,
        total_pr: 0,
        total: 0,

        ric: 0,
        rpr: 0,
        //table props
        search: '',
        data_table_loading: false,
        headers: [
          {text: 'No', value: 'no'},
          {text: 'CMF #', value: 'members.cmf_no'},
          {text: 'Effective Date', value: 'effective_date'},
          {text: 'Name', value: 'full_name'},
          {text: 'Shares', value: 'shares', sortable: false},
          {text: 'ASM', value: 'asm', sortable: false},
          {text: 'Interest Loan', value: 'i_loan', sortable: false},
          {text: 'IC', value: 'ic', sortable: false},
          {text: 'PR', value: 'pr', sortable: false},
          {text: 'Total', value: 'total', sortable: false},
          {text: 'Savings', value: 'action', sortable: false},
          {text: 'Share Capital', value: 'action2', sortable: false},
          {text: 'Status', value: 'kind_of_released', sortable: false},
        ],
        height: 430
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch_id', 'branch', 'branch_address', 'branch_contact_no', 'month_of', 'position']),
    },
    watch: {
      branch_id: function () {
        this.can_print = false
        this.type_of_member = ''
        this.list_of_new_member = []
      }
    },
    methods: {
      ...mapActions('transaction_months', ['retrieve_all_transaction_year']),
      ...mapActions('members_information', ['list_of_members_have_dividend_yearly']),
      ...mapActions('regular_savings_data', ['register_savings_deposit_from_yearly_dividend']),
      ...mapActions('yearly_dividend', ['list_of_yearly_dividend_history', 'release_dividend']),
      get_list_of_new_member_items() {
        this.list_of_yearly_dividend_history({
          year: this.year_of,
          status: this.type_of_member,
        })
          .then((response) => {
            this.can_print = true
            this.list_of_new_member = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      released_data() {
        if (confirm('Are you sure you want to release dividend?')) {
          if (this.list_of_new_member.length > 0) {
            this.get_list_of_new_member_items(1)
          } else {
            alert('No function in this matter')
          }
        }
      },
      released_dividend_data(value, status) {
        if (confirm('Continue?')) {
          this.release_dividend({
            id: value.id,
            status: status,
            branch_id: value.members.branch_id,
            member_id: value.member_id,
            amount: value.total,
            name: value.members.last_name + ', ' + value.members.first_name + ' ' + value.members.middle_name,
          })
            .then(() => {
              this.get_list_of_new_member_items()
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    }
  }
</script>
